class EnvService {
  baseUrl;
  uploadedAssetsBaseUrl;
  constructor() {
    switch (window.location.host) {
      // prod
      case 'dashboard.solvvy.com':
      case 'console.solvvy.com':
      case 'solvvy-production.firebaseapp.com':
        this.baseUrl = 'https://api.solvvy.com';
        this.uploadedAssetsBaseUrl = 'https://cdn.solvvy.com/deflect/customization/:name';
        break;
      case 'dashboard-green.solvvy.com':
      case 'console-green.solvvy.com':
      case 'solvvy-production-green.firebaseapp.com':
        this.baseUrl = 'https://api.prod-green.solvvy.com';
        this.uploadedAssetsBaseUrl = 'https://cdn.solvvy.com/deflect/customization/:name';
        break;
      // staging
      case 'dashboard.staging.solvvy.org':
      case 'dashboard.beta.dev7.solvvy.co':
      case 'dashboard-review.staging.solvvy.org':
      case 'solvvy-dev.firebaseapp.com':
        this.baseUrl = 'https://api-gateway.istio.staging.solvvy.org';
        this.uploadedAssetsBaseUrl = 'https://storage.googleapis.com/solvvy-dev-ui/deflect/customization/:name';
        break;
      case 'dashboard-green.staging.solvvy.org':
        this.baseUrl = 'https://api-gateway.istio.staging-green.solvvy.org';
        this.uploadedAssetsBaseUrl = 'https://storage.googleapis.com/solvvy-dev-ui/deflect/customization/:name';
        break;
      // local dev
      default:
        this.baseUrl = 'http://localhost:2999';
        this.uploadedAssetsBaseUrl = 'https://storage.googleapis.com/solvvy-dev-ui/deflect/customization/:name';
        break;
    }
  }

  getBaseUrl = () => this.baseUrl;
  getGraphQlUrl = () => `${this.baseUrl}/v2/graphql-public`;
  getUploadedAssetsBaseUrl = () => this.uploadedAssetsBaseUrl;
}

export default new EnvService();
